main {
  position: absolute;
  top: 50px;
}

section {
  border: 2px solid #AA5500;
  font-size: 16px;
  padding: 25px;
  margin-bottom: 20px;
}

code {
  color: rgb(53, 48, 48);
}

h2 {
  color: #551100;
}

input {
  margin-right: 20px;
  height: 25px;
}
.cidInput {
  width: 500px;
  margin-bottom: 10px;
}

label {
  font-style: italic;
  margin-right: 20px;
}

button {
  height: 25px;
  border: 1px solid yellow;
  color: yellow;
  background: rgb(19, 14, 14);
  text-align: center;
  justify-content: center;
}
button:active {
  border: 1px solid rgba(255, 255, 0, 0.63);
  color: rgba(255, 255, 0, 0.63);
}
button:hover {
  border: 1px solid rgb(255, 174, 0);
  color: rgb(255, 174, 0);
}

nav {
  position: fixed;
  z-index: 2;
  height: 50px;
  background-color: rgb(4, 4, 48);
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#navList {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.navListElement {
  margin-right: 20px;
}

a {
  color: yellow;
}

#inProgressList {
  list-style-type: none;
}

.inProgressListElement:hover {
  font-style: italic;
  font-weight: 900;
}